import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Login from "./pages/Login";
import About from "./Triangle/Pages/About";
import Stories from "./Triangle/Pages/Stories";
import Home from "./Triangle/Pages/Home";
import PageNotFound from "./Triangle/Components/Errror/PageNotFound";
import BlogEdit from "./Triangle/Components/Blog/BlogEdit";
import DashBoard from "./Triangle/Pages/DashBoard";

function App() {
  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogedit/:id" element={<BlogEdit />} />
        <Route path="/stories" element={<Stories />} />
        {/* <Route path="/stories" element={<HomePage />} /> */}
        <Route path="/help" element={<PageNotFound />} />
        <Route path="/dashboard" element={<DashBoard />} />
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
      {/* </Router> */}
    </div>
  );
}
export default App;
