import React from "react";
import NavbarAuth from "../Components/NavBar/NavbarAuth";
import FilesTreeView from "../Components/FileStructure/FilesTreeView";
import WorkspacesRoundedIcon from "@mui/icons-material/WorkspacesRounded";
import HubRoundedIcon from "@mui/icons-material/HubRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import LocalMallRoundedIcon from "@mui/icons-material/LocalMallRounded";
import LocalMoviesRoundedIcon from "@mui/icons-material/LocalMoviesRounded";
import FooterTriangle from "../Components/Footer/FooterTriangle";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import PermPhoneMsgRoundedIcon from "@mui/icons-material/PermPhoneMsgRounded";
import QuickreplyRoundedIcon from "@mui/icons-material/QuickreplyRounded";
import ScreenRotationTwoToneIcon from "@mui/icons-material/ScreenRotationTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import { useOrientation } from "react-use";
import WhiteBoard from "../Components/FabricWhiteBoard/WhiteBoard";

const DashBoard = () => {
  const { type } = useOrientation();
  return (
    <div className="">
      {type === "landscape-primary" ? (
        <div className="w-full">
          <NavbarAuth statusVal={"false"} />
          <div className="w-full h-fit flex felx-row justify-center ">
            <div className=" flex flex-col w-fit cursor-pointer h-min-screen bg-gray-300  justify-items-start border-r border-gray-500 ">
              <div className="flex text-base  text-gray-400 font-semibold w-full p-2 items-center justify-center ">
                Dashboard
              </div>
              <div className="flex flex-col justify-center">
                <div className=" bg-gray-500 px-7 py-2 flex flex-row space-x-2  items-center  w-full text-center  shadow-xl ">
                  <WorkspacesRoundedIcon
                    fontSize="medium"
                    className="text-gray-200"
                  />
                  <div className=" flex text-xl  font-extrabold text-gray-200 bg-gray-500">
                    WORKSPACE
                  </div>
                </div>
                <div className=" w-fit overflow-x-scroll">
                  <FilesTreeView />
                </div>
              </div>

              <div className="space-y-1">
                <div className=" bg-gray-500 px-7 py-2 flex flex-row space-x-2  items-center  w-full text-center  shadow-xl ">
                  <HubRoundedIcon fontSize="medium" className="text-gray-200" />
                  <div className=" flex text-xl  font-extrabold text-gray-200 bg-gray-500">
                    CONNECT
                  </div>
                </div>

                <div className=" p-2 flex flex-row items-center  w-full text-center hover:bg-gray-50 ">
                  <QuickreplyRoundedIcon
                    fontSize="small"
                    className="text-gray-500"
                  />
                  <div className=" text-lg flex   p-1 w-full text-start font-semibold text-gray-500">
                    Instagram
                  </div>
                </div>

                <div className=" p-2 flex flex-row items-center  w-full text-center hover:bg-gray-50 ">
                  <PermPhoneMsgRoundedIcon
                    fontSize="small"
                    className="text-gray-500"
                  />
                  <div className="text-lg flex   p-1 w-full text-start font-semibold text-gray-500">
                    Whatsapp
                  </div>
                </div>

                <div className=" p-2 flex flex-row items-center  w-full text-center hover:bg-gray-50 ">
                  <MailLockRoundedIcon
                    fontSize="small"
                    className="text-gray-500"
                  />
                  <div className="text-lg flex   p-1 w-full text-start font-semibold text-gray-500">
                    Secmesg
                  </div>
                </div>
              </div>

              <div className=" space-y-1">
                <div className=" bg-gray-500 px-7 py-2 flex flex-row space-x-2  items-center  w-full text-center  shadow-xl ">
                  <CurrencyRupeeRoundedIcon
                    fontSize="medium"
                    className="text-gray-200"
                  />
                  <div className=" flex text-xl  font-extrabold text-gray-200 bg-gray-500">
                    BUSINESS
                  </div>
                </div>
                <div className=" p-2 flex flex-row items-center  w-full text-center hover:bg-gray-50 ">
                  <LocalMallRoundedIcon
                    fontSize="small"
                    className="text-gray-500"
                  />
                  <div className="text-lg flex   p-1 w-full text-start font-semibold text-gray-500">
                    Triangle Shop
                  </div>
                </div>
                {/* <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Music
            </div>
            <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Family
            </div>
            <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Oragnic
            </div> */}
              </div>

              <div className="  space-y-1">
                <div className=" bg-gray-500 px-7 py-2 flex flex-row space-x-2  items-center  w-full text-center  shadow-xl ">
                  <ConstructionRoundedIcon
                    fontSize="medium"
                    className="text-gray-200"
                  />
                  <div className=" flex text-xl  font-extrabold text-gray-200 bg-gray-500">
                    UTILITIES
                  </div>
                </div>
                <div className=" p-2 flex flex-row items-center  w-full text-center hover:bg-gray-50 ">
                  <LocalMoviesRoundedIcon
                    fontSize="small"
                    className="text-gray-500"
                  />
                  <div className="text-lg flex   p-1 w-full text-start font-semibold text-gray-500">
                    Movies
                  </div>
                </div>
                {/* <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Music
            </div>
            <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Family
            </div>
            <div className="text-xl  p-2 w-full text-start font-bold text-gray-500">
              Triangle Oragnic
            </div> */}
              </div>
            </div>
            <div className="flex flex-col w-full h-full">
              {/* <div className="flex w-full p-2 items-center justify-center border-b">
                <div className="text-gray-300 text-base font-semibold">
                  WorkBoard
                </div>
              </div> */}
              {/* <div className="w-full h-screen "> */}
              <div className="bg-gray-500 text-xl text-gray-200 font-bold items-center flex flex-col p-2 w-full h-screen ">
                <div className="flex flex-row w-1/2 p-2">
                  <input
                    className="flex bg-gray-300 border border-gray-400  w-full font-semibold  rounded-lg text-start align-middle p-2 text-gray-500 "
                    placeholder="Type here..."
                  ></input>
                  <div
                    className="flex bg-gray-300 mx-1 rounded-lg p-1 cursor-pointer "
                    type="button"
                  >
                    <SearchIcon
                      fontSize="large"
                      className="text-gray-500 hover:text-gray-300 hover:bg-gray-500 justify-center rounded-lg "
                    />
                  </div>
                </div>

                <div className="bg-gray-300 w-full p-2 h-screen rounded-lg">
                  <div className=" justify-center text-gray-500 p-2">
                    <div className="bg-gray-50 rounded-lg ">
                      <WhiteBoard />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex w- h-full bg-gray-300"></div> */}
          {/* </div> */}
          <FooterTriangle />
        </div>
      ) : (
        <div className="flex justify-center w-full h-screen items-center">
          <div className="flex flex-col items-center space-y-5 ">
            <ScreenRotationTwoToneIcon
              fontSize="large"
              className="text-gray-400"
            />
            <div className="text-red-400 text-lg flex font-bold">
              {" "}
              Please rotate your device to horizontal!
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashBoard;
