import React from "react";

const FooterTriangle = () => {
  return (
    <div className="w-100  border-t border-gray-400   bg-black p-2 flex-col pt-8 justify-center ">
      <div className=" flex font-bold text-xl text-gray-200 justify-center">
        Triangle
      </div>
      <div className="font-light flex text-sm text-gray-100 justify-center">
        ⓒ 2024
      </div>
    </div>
  );
};

export default FooterTriangle;
