import React, { useState } from "react";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import logo from "../../../assets/trianglelogo.svg";
// import FooterTriangle from "../Footer/FooterTriangle";
import LockIcon from "@mui/icons-material/Lock";
import FadeLoader from "react-spinners/FadeLoader";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginTriangle = () => {
  const navigate = useNavigate();
  const gotToPage = () => {
    navigate("/dashboard");
  };
  const [loader, setLoader] = useState("false");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("true");

  console.log(username);
  console.log(password);
  const handleLogin = async () => {
    const data = {
      email: username,
      password: password,
    };
    setLoader("true");

    try {
      if (!username || !password) {
        setError("Please enter both username and password.");
        return;
      }

      const response = await axios.post(
        "https://apis.triangletechecosystem.com/auth/signin",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);

      console.log("Login successful:", response.data);
      if (response.data) {
        setLoader("false");
        gotToPage();
      }
    } catch (error) {
      console.error(
        "Login failed:",
        error.response ? error.response.data : error.message
      );
      setError("Invalid username or password!");
      setLoader("false");
      setAlert("false");
    }
  };

  return (
    <>
      <div>
        {/* card */}
        <div className="flex w-full  justify-center p-8">
          <div className="flex flex-col">
            {/* <div className="flex py-9 justify-center">
          <div className=" font-black text-3xl  md:text-5xl">
            T R I A N G L E
          </div>
        </div> */}
            <div className="flex  justify-center pb-12 ">
              <div className=" font-extralight text-4xl  md:text-5xl">
                Auth In
              </div>
            </div>
            {/* card */}
            <div class="w-full flex flex-col max-w-sm p-8 bg-gray-200 border rounded-xl shadow sm:p-6 md:p-8">
              <div className=" flex flex-row w-full justify-center">
                <div className=" flex w-full justify-start">
                  {error && (
                    <p className="text-red-700 font-semibold text-sm">
                      {error}
                    </p>
                  )}
                </div>
                <div className=" flex  justify-end">
                  <LockIcon
                    fontSize="small"
                    sx={{ color: alert ? "grey" : "red" }}
                  />
                </div>
              </div>
              <form class="space-y-4" action="#">
                <div className=" flex flex-col  items-center">
                  <label
                    for="id"
                    class=" block mb-2 text-3xl text-zinc-600 font-black"
                  >
                    ID
                  </label>
                  <input
                    type="email"
                    name="id"
                    id="email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-gray-50 h-14 border font-regular border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-300 dark:border-slate-300 dark:placeholder-gray-400 dark:text-slate-600"
                    placeholder="name@company.com"
                    required
                  />
                </div>
                <div className=" flex flex-col  items-center">
                  <label
                    for="key"
                    class="block mb-2 text-3xl text-zinc-600 font-black"
                  >
                    Key
                  </label>
                  <input
                    type="password"
                    name="key"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="key"
                    className=" h-14 bg-gray-50 border font-semibold border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-300 dark:border-slate-300 dark:placeholder-gray-400 dark:text-slate-500"
                    required
                  />
                </div>
                <div class="flex text-center flex-row  justify-center pt-2">
                  <div class="text-xs px-2 flex font-light text-zinc-900 ">
                    *Only authorised person can allow to login.This is private
                    website!
                  </div>
                  {loader === "true" ? (
                    <div className="flex justify-center items-center">
                      <FadeLoader
                        height="12"
                        width="6"
                        radius={5}
                        color="grey"
                        margin="0"
                        speedMultiplier="5"
                        onTimeUpdate={5000}
                      />
                    </div>
                  ) : (
                    <div className="flex justify-center">
                      <button
                        className="bg-gray-500 p-2 border border-slate-300 rounded hover:bg-sky-600 md: "
                        type="button"
                        onClick={() => {
                          handleLogin();

                          // gotToPage();
                        }}
                      >
                        <FingerprintIcon
                          fontSize="large"
                          className=" text-zinc-100 hover:text-blue-200 "
                        />
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginTriangle;
