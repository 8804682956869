import React from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const GoToPage = (item) => {
    navigate(item);
  };
  return (
    <div className="w-full h-screen flex items-center justify-center flex-col">
      {/* <div className="w-full flex border-b p-3 ">
        <ArrowBackIosRoundedIcon fontSize="medium" sx={{ color: "grey" }} />
      </div> */}
      <div className="flex flex-col space-y-5 justify-items-center items-cente p-5">
        {" "}
        <div className="flex justify-items-center items-center font-extralight text-5xl md:text-8xl text-gray-500">
          Hmm...
        </div>
        <div className="text-gray-400 font-regular justify-items-center text-sm  md:text-2xl font-semibold">
          Error code : 404
        </div>
        <div className="text-gray-400 font-regular text-xs md:text-lg">
          It seems that you're lost in a perpetual black hole. Let us help guid
          you out and get you!
        </div>
      </div>
      <div className="flex flex-row p-5 space-x-5 justify-center">
        <div
          onClick={() => {
            GoToPage(-1);
          }}
          type="button"
          className="flex bg-gray-200 text-center items-center text-xs m-2 p-2 md:m-0 md:text-lg text-gray-400 font-semibold cursor-pointer hover:bg-gray-300 hover:text-gray-400 border border-gray-300 "
        >
          Back
        </div>
        <div
          onClick={() => {
            GoToPage("/home");
          }}
          type="button"
          className="flex bg-gray-200 text-center items-center text-xs m-2 p-2 md:m-0 md:text-lg text-gray-400 p-2 font-semibold cursor-pointer hover:bg-gray-300 hover:text-gray-400 border border-gray-300 "
        >
          Home
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
