import React, { useRef, useState } from "react";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
// import RestartAltRoundedIcon from "@mui/icons-material/RestartAltRounded";
// import JavascriptRoundedIcon from "@mui/icons-material/JavascriptRounded";
// import CssRoundedIcon from "@mui/icons-material/CssRounded";
// import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
// import FolderRoundedIcon from "@mui/icons-material/FolderRounded";
// import ListRoundedIcon from "@mui/icons-material/ListRounded";
// import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import DataSaverOffRoundedIcon from "@mui/icons-material/DataSaverOffRounded";
import TreeView from "react-accessible-treeview";
import MoonLoader from "react-spinners/MoonLoader";
import cx from "classnames";

const initialData = [
  {
    name: "",
    id: 0,
    children: [1, 2, 3],
    parent: null,
  },
  {
    name: "Project 1.0",
    children: [],
    id: 1,
    parent: 0,
    isBranch: true,
  },
  {
    name: "Project 2.0",
    children: [4, 5],
    id: 2,
    parent: 0,
    isBranch: true,
  },
  {
    name: "Project 3.0",
    children: [],
    id: 3,
    parent: 0,
    isBranch: true,
  },
  {
    name: "Project 4.0",
    children: [],
    id: 4,
    parent: 2,
  },
  {
    name: "Project 5.0",
    children: [],
    id: 5,
    parent: 2,
  },
];

const FilesTreeView = () => {
  const loadedAlertElement = useRef(null);
  const [data, setData] = useState(initialData);
  const [nodesAlreadyLoaded, setNodesAlreadyLoaded] = useState([]);

  const updateTreeData = (list, id, children) => {
    const data = list.map((node) => {
      if (node.id === id) {
        node.children = children.map((el) => {
          return el.id;
        });
      }
      return node;
    });
    return data.concat(children);
  };

  const onLoadData = ({ element }) => {
    return new Promise((resolve) => {
      if (element.children.length > 0) {
        resolve();
        return;
      }
      setTimeout(() => {
        setData((value) =>
          updateTreeData(value, element.id, [
            {
              name: `Child Node ${value.length}`,
              children: [],
              id: value.length,
              parent: element.id,
              isBranch: true,
            },
            {
              name: "Another child Node",
              children: [],
              id: value.length + 1,
              parent: element.id,
            },
          ])
        );
        resolve();
      }, 1000);
    });
  };

  const wrappedOnLoadData = async (props) => {
    const nodeHasNoChildData = props.element.children.length === 0;
    const nodeHasAlreadyBeenLoaded = nodesAlreadyLoaded.find(
      (e) => e.id === props.element.id
    );

    await onLoadData(props);

    //     if (nodeHasNoChildData && !nodeHasAlreadyBeenLoaded) {
    //       const el = loadedAlertElement.current;
    //       setNodesAlreadyLoaded([...nodesAlreadyLoaded, props.element]);
    //       el && (el.innerHTML = `${props.element.name} loaded`);

    //       // Clearing aria-live region so loaded node alerts no longer appear in DOM
    //       setTimeout(() => {
    //         el && (el.innerHTML = "");
    //       }, 5000);
    //     }
    //   };
  };

  return (
    <>
      <div>
        {/* <div
          className=" overflow-hidden w-full whitespace-nowrap "
          ref={loadedAlertElement}
          role="alert"
          aria-live="polite"
        ></div> */}
        {/* <div className="text-gray-600 text-xl w-full text-center p-1 m-2 font-bold">
          Project name
        </div> */}
        <div className="checkbox">
          <TreeView
            data={data}
            aria-label="Checkbox tree"
            onLoadData={wrappedOnLoadData}
            multiSelect
            propagateSelect
            togglableSelect
            propagateSelectUpwards
            nodeRenderer={({
              element,
              isBranch,
              isExpanded,
              isSelected,
              isHalfSelected,
              getNodeProps,
              level,
              handleSelect,
              handleExpand,
            }) => {
              const branchNode = (isExpanded, element) => {
                return isExpanded && element.children.length === 0 ? (
                  <>
                    <div className="">
                      <MoonLoader
                        size={14}
                        height="15"
                        width="8"
                        color="#2d3030"
                        margin="0"
                        speedMultiplier="1.5"
                      />
                    </div>
                  </>
                ) : (
                  <ArrowIcon isOpen={isExpanded} />
                );
              };
              return (
                <div
                  {...getNodeProps({ onClick: handleExpand })}
                  style={{ marginLeft: 40 * (level - 1) }}
                  className="  flex flex-row items-center w-full p-1 space-x-1 "
                >
                  <div className="flex">
                    {isBranch && branchNode(isExpanded, element)}
                  </div>
                  <CheckBoxIcon
                    className=" text-gray-400"
                    onClick={(e) => {
                      handleSelect(e);
                      e.stopPropagation();
                    }}
                    variant={
                      isHalfSelected ? "some" : isSelected ? "all" : "none"
                    }
                  />
                  <span className="text-gray-500 text-lg font-semibold ">
                    {element.name}
                  </span>
                </div>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

const ArrowIcon = ({ isOpen, className }) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    { [`${baseClass}--closed`]: !isOpen },
    { [`${baseClass}--open`]: isOpen },
    className
  );
  return (
    <ArrowRightRoundedIcon
      fontSize="large"
      sx={{ color: "grey" }}
      className={classes}
    />
  );
};

const CheckBoxIcon = ({ variant, ...rest }) => {
  switch (variant) {
    case "all":
      return <CheckBoxRoundedIcon fontSize="small" {...rest} />;
    case "none":
      return <SquareRoundedIcon fontSize="small" {...rest} />;
    case "some":
      return <IndeterminateCheckBoxRoundedIcon fontSize="small" {...rest} />;
    default:
      return null;
  }
};

export default FilesTreeView;
