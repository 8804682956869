import React from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FooterTriangle from "../Components/Footer/FooterTriangle";
import NavbarAuth from "../Components/NavBar/NavbarAuth";
import { useNavigate } from "react-router-dom";
import ModernCard from "../Components/Card/ModernCard";

const Home = () => {
  const navigate = useNavigate();
  const gotToPage = () => {
    navigate("/login");
  };
  return (
    <>
      <NavbarAuth statusVal="false" />
      <div className="w-100 h-screen">
        <div className="z-0 w-full h-screen flex flex-row p-10 justify-evenly items-center absolute">
          <div className="w-9 flex-row h-9 md:w-56 md:h-56 bg-gradient-to-b from-gray-400 to gray-100 animate-pulse rounded-full"></div>
          <div className="w-14 rotate-12  flex-row h-14 md:w-28 md:h-28  bg-gradient-to-t from-gray-500 to gray-100 animate-pulse "></div>
          <div className="w-0 h-0  rotate-45   border-l-[50px] border-l-transparent border-t-[75px] border-t-gray-300 border-r-[50px] border-r-transparent  animate-pulse"></div>
          <div className=" w-16 h-16 md:w-36 flex-row md:h-36  bg-gradient-to-t from-gray-400 to gray-100 rounded-full animate-pulse"></div>
          <div className=" w-6 h-6 md:w-56 -rotate-12 flex-row md:h-56  bg-gradient-to-l from-gray-600 to gray-100 animate-pulse  "></div>
        </div>
        <div className="flex flex-col  w-full h-full  justify-evenly  justify-items-center items-cente">
          <div className="flex flex-col w-full    items-center">
            <div className="flex flex-col z-10 w-full  justify-center space-y-5 items-center">
              <div className="flex items-center">
                <div className="text-3xl font-light text-gray-800 sm:text-4xl md:text-6xl ">
                  O R I G I N
                </div>
              </div>
              <div className="flex">
                <div className="text-xl font-BOO text-gray-400 sm:text-2xl md:text-4xl ">
                  OF
                </div>
              </div>
              <div className="flex flex-col">
                <div className="text-5xl font-black text-gray-500 sm:text-6xl md:text-8xl ">
                  T R I A N G L E
                </div>
                <div>
                  <div className=" flex text-red-500  justify-end ">
                    <div class=" font-MRD text-xl sm:text-2xl md:text-4xl">
                      Tech Ecosystem
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="z-20 flex flex-col space-y-3 w-full justify-center items-center ">
            <div className="text-xl font-light text-gray-700 sm:text-2xl md:text-3xl ">
              Auth In
            </div>
            <button
              className="bg-black p-1 border border-zinc-800 rounded hover:bg-gray-400 md: "
              type="button"
              onClick={() => {
                gotToPage();
              }}
            >
              <FingerprintIcon
                fontSize="medium"
                className=" text-gray-100 hover:text-black "
              />
            </button>
            <div className="text-xs font-light text-black sm:text-xs md:text-sm  md:font-regular">
              This website is under development kindly proceed with caution.
            </div>
            <div className="text-sm font-bold text-gray-900 "> Coming Soon</div>
            {/* <ModernCard /> */}
          </div>
        </div>
      </div>

      <div>
        <FooterTriangle />
      </div>
    </>
  );
};

export default Home;
