import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import logo from "../../../assets/trianglelogo.svg";
import { Menu, Transition } from "@headlessui/react";
import Help from "@mui/icons-material/Help";
import KeyTwoToneIcon from "@mui/icons-material/KeyTwoTone";
import KeyboardCommandKeyTwoToneIcon from "@mui/icons-material/KeyboardCommandKeyTwoTone";
import { useNavigate } from "react-router-dom";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import InterestsRoundedIcon from "@mui/icons-material/InterestsRounded";
import WebStoriesRoundedIcon from "@mui/icons-material/WebStoriesRounded";
import LockIcon from "@mui/icons-material/Lock";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const NavbarAuth = ({ statusVal }) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(statusVal);
  const [pagePath, setPagePath] = useState("");
  const gotToPage = (item) => {
    navigate(item);
  };
  return (
    <div>
      <div className="flex justify-between w-full bg-black border-b border-gray-400 p-1">
        {" "}
        <div className="flex items-center px-2">
          <img className="w-auto h-4  md:h-5 " src={logo} alt="" />
        </div>
        <div className="p-1">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 bg-black px-3 py-2    ">
                <MoreHorizIcon
                  className=" cursor-pointer"
                  onClick={console.log("hey")}
                  fontSize="medium"
                  style={{ color: "white" }}
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10  w-36 origin-top-right divide-y divide-gray-200 rounded-md bg-gray-100  focus:outline-none">
                <div className="py-1">
                  <div className="flex flex-row">
                    <div className="flex items-center px-1">
                      <Help sx={{ color: "grey" }} />
                    </div>
                    <Menu.Item className="flex w-full">
                      {({ active }) => (
                        <a
                          onClick={() => {
                            gotToPage("/help");
                          }}
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-300 text-gray-500"
                              : "text-gray-400",
                            "block  px-2 py-2 text-sm font-bold cursor-default"
                          )}
                        >
                          Help
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="flex flex-row">
                    <div className="flex items-center px-1">
                      <KeyboardCommandKeyTwoToneIcon sx={{ color: "grey" }} />
                    </div>
                    <Menu.Item className="flex w-full">
                      {({ active }) => (
                        <a
                          onClick={() => {
                            gotToPage("/about");
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-300 text-gray-500"
                              : "text-gray-400",
                            "block px-2 py-2 text-sm font-bold cursor-default"
                          )}
                        >
                          About
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </div>
                <div className="py-1">
                  <div className="flex flex-row">
                    <div className="flex items-center px-1">
                      <KeyTwoToneIcon sx={{ color: "grey" }} />
                    </div>
                    <Menu.Item className="flex w-full">
                      {({ active }) => (
                        <a
                          onClick={() => {
                            gotToPage("/dashboard");
                          }}
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-300 text-gray-500"
                              : "text-gray-400",
                            "block px-2 py-2 text-sm font-bold cursor-default"
                          )}
                        >
                          Connect
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>

      {status === "false" ? (
        <></>
      ) : (
        <div className="w-full flex flex-row  cursor-pointer space-x-5 p-2 md:space-x-8">
          <div className="flex items-center">
            <ArrowBackIosRoundedIcon fontSize="small" sx={{ color: "grey" }} />
          </div>
          <div
            className="flex-row flex space-x-1"
            typeof="button"
            onClick={() => gotToPage("/home")}
          >
            <div className="flex items-center">
              <InterestsRoundedIcon fontSize="small" sx={{ color: "grey" }} />
            </div>
            <div className="text-gray-400 items-center flex  hover:text-gray-600 font-bold text-sm md:text-lg ">
              {" "}
              Home
            </div>{" "}
          </div>{" "}
          <div
            className="flex-row flex space-x-1 "
            typeof="button"
            onClick={() => gotToPage("/stories")}
          >
            <div className="flex items-center">
              <WebStoriesRoundedIcon fontSize="small" sx={{ color: "grey" }} />
            </div>
            <div className="text-gray-400 items-center hover:text-gray-600 flex font-bold text-sm md:text-lg ">
              {" "}
              Stories
            </div>{" "}
          </div>
          <div
            className="flex-row flex space-x-1"
            typeof="button"
            onClick={() => {
              gotToPage("/login");
            }}
          >
            <div className="flex items-center">
              <LockIcon fontSize="small" sx={{ color: "grey" }} />
            </div>
            <div className="text-gray-400 items-center flex  hover:text-gray-600 font-bold text-sm md:text-lg ">
              {" "}
              Auth in
            </div>{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarAuth;
