import React, { useState } from "react";
import { useEffect } from "react";

const SearchResultList = ({ results }) => {
  console.log("SearchResultList" + results);

  return (
    <div className="flex absolute bg-zinc-800/10 rounded-lg backdrop-blur-sm overflow-x-hidden  overflow-y-auto scrollbar-webkit scrollbar-thin">
      <div className=" text-zinc-700 font-light text-md p-2">
        {results &&
          results.slice(0, 10).map((result, id) => (
            <div>
              {results.id === null ? (
                <div>
                  <div className="p-2">Opps, No result found ! </div>
                </div>
              ) : (
                <div className=" cursor-pointer hover:bg-sky-800/70 rounded-md hover:text-zinc-300 p-1 px-2">
                  <div key={id}>{result.heading}</div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchResultList;
