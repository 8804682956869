import React, { useState, useEffect } from "react";
import { json, Link } from "react-router-dom";
import NavbarAuth from "../Components/NavBar/NavbarAuth";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import SearchIcon from "@mui/icons-material/Search";
import BlogDataService from "../Service/DataService";
import FooterTriangle from "../Components/Footer/FooterTriangle";
import axiosRequest from "../Service/http-common";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import NoBlog from "../../assets/NoBlog.png";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import WebStoriesRoundedIcon from "@mui/icons-material/WebStoriesRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import KeyboardCapslockRoundedIcon from "@mui/icons-material/KeyboardCapslockRounded";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import SearchResultList from "../Components/Search/SearchResultList";

const Stories = () => {
  const [blog, setBlog] = useState([]);
  const [currentBlog, setCurrentBlog] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchHeading, setSearchHeadinge] = useState("");
  const [data, setData] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    retrieveBlog();
  }, []);

  const retrieveBlog = async (value) => {
    try {
      const response = await axiosRequest.get("/blog");
      const blog = response.data;
      setBlog(response.data);
      // console.log(blog);

      const results = blog.filter((data) => {
        return (
          value &&
          data &&
          data.heading &&
          data.heading.toLowerCase().includes(value.toLowerCase())
        );
      });

      setData(results);

      console.log(results);
    } catch (e) {
      console.log(e);
    }
  };

  const HandleChange = (value) => {
    setSearchHeadinge(value);
    retrieveBlog(value);
  };

  const refreshList = () => {
    retrieveBlog();
    setCurrentBlog(null);
    setCurrentIndex(-1);
  };

  const setActiveBlog = (blog, index) => {
    setCurrentBlog(blog);
    setCurrentIndex(index);
  };

  const removeAllBlog = () => {
    BlogDataService.removeAll()
      .then((response) => {
        console.log(response.data);
        refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const findByHeading = () => {
    BlogDataService.findByHeading(searchHeading)
      .then((response) => {
        setBlog(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const MenuOpen = () => {
    setOpenMenu(true);
  };
  const MenuClose = () => {
    setOpenMenu(false);
  };
  const OnEditClick = () => {
    navigate("/blogedit/" + currentBlog.id);
  };

  const OpenSideBar = () => {
    setOpenSideBar(true);
  };
  const CloseSideBar = () => {
    setOpenSideBar(false);
  };

  return (
    <div className="flex flex-col justify-between min-h-screen">
      <NavbarAuth />
      <div className="flex">
        <div className=" w-full h-full m-5 space-y-20  ">
          <div className=" flex flex-row w-full  justify-center items-center space-x-5 ">
            <div className="flex flex-row px-5 items-center  justify-start ">
              <WebStoriesRoundedIcon
                sx={{ color: "#008eab", fontSize: "4.5rem" }}
              />
              <div className="flex flex-col">
                <div className="font-MRD text-zinc-800/75 text-5xl">
                  Strories
                </div>
                {/* <div className="font-MRD">Triangle</div> */}
              </div>
            </div>
            <div className="flex flex-col space-y-1">
              <div className="bg-zinc-200  rounded-md flex flex-rol w-full">
                <input
                  type="text"
                  className="bg-zinc-200 w-full rounded-3xl py-1 px-3 cursor-pointer  placeholder-zinc-400 placeholder:font-light placeholder:italic text-zinc-800 font-semibold text-md focus:outline-none"
                  placeholder="Search stories"
                  value={searchHeading}
                  onChange={(e) => HandleChange(e.target.value)}
                />
                <div className="bg-zinc-200 text-zinc-700 p-2 rounded-full hover:text-sky-500">
                  <button className="" type="button" onClick={findByHeading}>
                    <SearchIcon fontSize="medium" />
                  </button>
                </div>
              </div>
              {searchHeading && (
                <div className="">
                  <SearchResultList results={data} />
                </div>
              )}
            </div>
          </div>
          <div className=" w-full flex flex-row m-1">
            <div className="w-1/4 flex flex-col space-y-3 overflow-ellipsis  ">
              <div className="  text-gray-100 p-5 text-2xl font-MRD px-3 bg-gradient-to-b from-sky-500 to-sky-800 rounded-t-2xl text-center w-full">
                Stories
              </div>
              <div className="overflow-scroll overflow-ellipsis h-screen overflow-x-hidden scrollbar-webkit scrollbar-thin ">
                <ul className=" space-y-2 p-3 ">
                  {blog &&
                    blog.map((blog, index) => (
                      <div>
                        <div className="bg-sky-900/20 w-fit p-1 px-6 rounded-t-lg ">
                          <div className="text-zinc-500 font-passionone text-sm ">
                            Technology
                          </div>
                        </div>
                        <div
                          className={
                            " flex flex-col bg-zinc-200/40 cursor-pointer p-6 rounded-tr-lg rounded-b-lg w-50% " +
                            (index === currentIndex ? "active" : "")
                          }
                        >
                          <li
                            className=" text-lg font-bold text-zinc-900/75  hover:text-sky-400 hover:bg-zinc-100/50"
                            onClick={() => setActiveBlog(blog, index)}
                            key={index}
                          >
                            {blog.heading}
                          </li>
                          <div className="flex-row flex justify-between my-2 ">
                            <div className=" font-light text-zinc-400 text-xs">
                              {blog.author}
                            </div>
                            <div className="flex flex-row items-center space-x-1">
                              {" "}
                              <div className=" font-extrabold text-red-800 text-xs">
                                4.9
                              </div>
                              <VisibilityRoundedIcon
                                sx={{ fontSize: "10px", color: "grey" }}
                              />
                            </div>
                            <div className="flex flex-row space-x-1">
                              {" "}
                              <div className=" font-semibold text-green-600 text-xs">
                                1.2M
                              </div>
                              <StarRoundedIcon
                                sx={{ fontSize: "15px", color: "orange" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </ul>
              </div>
            </div>

            {/* <button
              className="m-3 btn btn-sm btn-danger"
              onClick={removeAllBlog}
            >
              Remove All
            </button> */}

            <div className=" flex-1 rounded-xl my-3 p-4 h-fit ">
              <div>
                {currentBlog ? (
                  <div className="">
                    <div className="m-3 flex flex-col w-full">
                      <div className=" justify-start w-full">
                        <label className="text-orange-700 text-4xl font-bold">
                          {currentBlog.heading}
                        </label>{" "}
                      </div>
                      <div className="text-sky-500/50 font-BOO italic text-justify">
                        {currentBlog.subHeading}
                      </div>
                      {/* <div className=" justify-end ">
                        <Link to={"/blogedit/" + currentBlog.id} className="">
                          <ModeRoundedIcon
                            fontSize="medium"
                            sx={{ color: "gray" }}
                          />
                        </Link>
                      </div>{" "} */}
                    </div>
                    <div className=" flex flex-row space-x-3 p-3 pt-0 items-center">
                      <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-400/20  font-light w-fit p-2 px-4 rounded-full text-sm text-sky-800">
                        <label>@{currentBlog.author}</label>{" "}
                        <VerifiedRoundedIcon fontSize="small" />
                      </div>
                      <div className="flex flex-row items-center space-x-1">
                        <PlaylistAddCheckCircleRoundedIcon
                          sx={{ fontSize: "12px", color: "gray" }}
                        />
                        <div className="font-semibold text-xs text-zinc-600/30">
                          {currentBlog.published ? "Published" : "Pending"}
                        </div>
                      </div>
                    </div>
                    <div className="text-zinc-600 font-light text-justify m-3 mx-6">
                      {currentBlog.introDescription}
                    </div>
                    <div className="text-zinc-500 font-bold text-justify m-3 mx-6">
                      {currentBlog.mainDescription}
                    </div>
                    <div className="text-slate-500 italic font-semibold text-justify m-3 mx-6">
                      {currentBlog.conclusionDescription}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <div className=" text-center">
                      <img src={NoBlog} height={320} width={320} />
                      <p className="text-gray-500 font-bold text-sm">
                        Please select the stories. . .
                      </p>
                      <p className="text-gray-500 font-bold text-xl">Or</p>
                      <button>
                        <div className="flex flex-row justify-center items-center bg-gradient-to-b from-sky-500 to-sky-800 rounded-md p-2 px-4 m-1 text-center text-zinc-100 font-bold ">
                          <div className="px-1">
                            <BorderColorRoundedIcon fontSize="small" />
                          </div>
                          <div className="">Create</div>
                        </div>
                      </button>
                      <p className="text-gray-400 font-regular text-sm">
                        Create your own story and post to the world.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {openSideBar ? (
              <>
                {currentBlog ? (
                  <div className=" w-1/6 flex flex-col bg-gradient-to-b from-sky-500 to-sky-800 rounded-xl justify-between items-center ">
                    <div className="space-y-4 w-full">
                      <div className="flex flex-row items-center pt-2 mb-10">
                        <div
                          className=" flex p-1 justify-start items-center "
                          type="button"
                          onClick={CloseSideBar}
                        >
                          <ArrowBackIosNewRoundedIcon
                            fontSize="large"
                            sx={{ color: "white", rotate: "180deg" }}
                          />
                        </div>
                        <div className="flex text-white font-extrabold text-2xl w-full items-center justify-center ">
                          {" "}
                          MetaData
                        </div>
                      </div>

                      {/* <div className="text-xl font-passionone text-zinc-50 border-b-2 text-center w-full ">
                    MetaData
                  </div> */}

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">Author</div>
                        <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                          {currentBlog.author}
                        </div>
                      </div>

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">Views</div>
                        <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                          {" "}
                          <div className=" font-extrabold text-zinc-100 text-lg">
                            4.9M
                          </div>
                          <VisibilityRoundedIcon sx={{ color: "white" }} />
                        </div>
                      </div>

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">Rating</div>
                        <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                          {" "}
                          <div className=" font-extrabold text-zinc-100 text-lg">
                            4.2
                          </div>
                          <StarRoundedIcon sx={{ color: "orange" }} />
                        </div>
                      </div>

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">Likes</div>
                        <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                          {" "}
                          <div className=" font-extrabold text-zinc-100 text-lg">
                            {currentBlog.likes == null
                              ? "0"
                              : currentBlog.likes}
                          </div>
                          <FavoriteRoundedIcon sx={{ color: "red" }} />
                        </div>
                      </div>

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">
                          Followers
                        </div>
                        <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                          {" "}
                          <div className=" font-extrabold text-zinc-100 text-lg">
                            400
                          </div>
                          <GroupRoundedIcon sx={{ color: "white" }} />
                        </div>
                      </div>

                      <div className="flex flex-col items-center">
                        <div className="text-zinc-100 font-light">Post</div>
                        <div className="flex flex-row space-x-1 items-center">
                          {" "}
                          <div className=" flex flex-row space-x-1 text-center justify-items-center bg-zinc-50/10  font-extrabold w-fit p-2 px-4 rounded-full text-lg text-sky-50">
                            11 May 2024
                          </div>
                        </div>
                      </div>
                    </div>

                    {openMenu ? (
                      <div className="w-full flex flex-col space-y-3 items-center p-3">
                        <div>
                          {" "}
                          <button
                            className=" rounded-full items-center"
                            onClick={MenuClose}
                            type="button"
                          >
                            <KeyboardCapslockRoundedIcon
                              fontSize="medium"
                              sx={{ color: "white", rotate: "180deg" }}
                            />
                          </button>
                        </div>
                        <div className="flex-col flex bg-zinc-50/10 w-full p-2 rounded-xl  items-center ">
                          {" "}
                          <button
                            onClick={OnEditClick}
                            className="flex w-full flex-row justify-center items-center  rounded-md p-2 px-4 m-1 text-center text-zinc-800 bg-slate-50  font-bold "
                          >
                            <div className="px-1">
                              <BorderColorRoundedIcon fontSize="small" />
                            </div>
                            Edit Story
                          </button>
                          <div className="text-zinc-400 cursor-pointer text-xs font-bold pt-2 hover:text-zinc-50">
                            Report Story
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="pb-3">
                          <button
                            className=" rounded-full "
                            onClick={MenuOpen}
                            type="button"
                          >
                            <KeyboardCapslockRoundedIcon
                              fontSize="medium"
                              sx={{ color: "white" }}
                            />
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            ) : (
              <>
                {currentBlog ? (
                  <div>
                    <div className=" flex flex-col  justify-center h-screen  ">
                      <div
                        className="p-1 w-full  items-center bg-gray-300 rounded-lg"
                        type="button"
                        onClick={OpenSideBar}
                      >
                        <ArrowBackIosNewRoundedIcon
                          fontSize="medium"
                          sx={{ color: "rgb(20, 142, 199)" }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* 
          <div className="w-full border rounded-tl-xl rounded-tr-xl border-gray-500 p-5">
            <div>Hyderabad</div>
          </div>
          <div className="bg-gray-300 p-8 rounded-bl-xl  rounded-br-xl border border-gray-400 border-b-0 text-4xl text-gray-500 font-bold">
            Origin Of Company
          </div>
        </div> */}
      {/* </div> */}
      ;
      <FooterTriangle />
    </div>
  );
};

export default Stories;
