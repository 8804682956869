import http from "../Service/http-common";

const getAll = () => {
  console.log(http.get("/blog"));
  return http.get("/blog");
};

const get = (id) => {
  return http.get(`/blog/${id}`);
};

const create = (data) => {
  return http.post("/blog", data);
};

const update = (id, data) => {
  return http.put(`/blog/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/blog/${id}`);
};
const removeAll = () => {
  return http.delete(`/blog`);
};

const findByHeading = (heading) => {
  return http.get(`/blog?heading=${heading}`);
};

const DataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByHeading,
};

export default DataService;
