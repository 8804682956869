import React, { useState } from "react";
import NavbarAuth from "../Components/NavBar/NavbarAuth";
import FooterTriangle from "../Components/Footer/FooterTriangle";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { useNavigate } from "react-router-dom";

const About = () => {
  const [pagePath, setPagePath] = useState("-1");
  const GoToStories = () => {
    setPagePath("/stories");
  };
  console.log("............" + GoToStories);
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <NavbarAuth call={pagePath} />
      </div>
      <div className="flex-col flex md:flex-row">
        <div className="flex-col w-full">
          <div className="flex justify-center py-5 w-full ">
            {" "}
            <div className="text-2xl font-semibold text-zinc-500 m-3 md:text-4xl md:m-8">
              About 'T R I A N G L E'
            </div>
          </div>

          <div className="flex-col  py-5 w-full bg-gray-200">
            <div className="flex w-full justify-end px-4">
              <ModeCommentIcon fontSize="small" sx={{ color: "grey" }} />
            </div>
            <div className="text-xl text-center font-thin text-zinc-900 m-4 md:text-4xl">
              “A computer is like a violin. You can imagine it making beautiful
              music, but you have to learn how to play it.”
            </div>
          </div>
          <div className="flex-col justify-center text-justify space-y-4 md: p-5">
            <div className="flex text-sm font-regular text-zinc-600  md:m-9 md: text-lg">
              Triangle is India based Private Company, Recently introduced to
              the world. By CEO Ritul Lunagaria who promise to the world makes
              this world more liveable. We are on the Misson "Triangle World".
            </div>

            <div className=" flex text-sm font-regular text-zinc-600 md:m-9 md: text-lg ">
              According to Fortune, as of 2020, the ten largest technology
              companies by revenue are: Apple Inc., Samsung, Foxconn, Alphabet
              Inc., Microsoft, Huawei, Dell Technologies, Hitachi, IBM, and
              Sony.[5] Amazon has higher revenue than Apple, but is classified
              by Fortune in the retail sector.[6] The most profitable listed in
              2020 are Apple Inc., Microsoft, Alphabet Inc., Intel, Meta
              Platforms, Samsung, and Tencent.[5] Apple Inc., Alphabet Inc.
              (owner of Google), Meta Platforms (owner of Facebook), Microsoft,
              and Amazon.com, Inc. are often referred to as the Big Five
              multinational technology companies based in the United States.
              These five technology companies dominate major functions,
              e-commerce channels, and information of the entire Internet
              ecosystem. As of 2017, the Big Five had a combined valuation of
              over $3.3 trillion and make up more than 40 percent of the value
              of the Nasdaq-100 index.[7] Many large tech companies have a
              reputation for innovation, spending large sums of money annually
              on research and development. According to PwC's 2017 Global
              Innovation 1000 ranking, tech companies made up nine of the 20
              most innovative companies in the world, with the top R&D spender
              (as measured by expenditure) being Amazon, followed by Alphabet
              Inc., and then Intel.[8] As a result of numerous influential tech
              companies and tech startups opening offices in proximity to one
              another, a number of technology districts have developed in
              various areas across the globe.[9] These include: Silicon Valley
              in the San Francisco Bay Area, Silicon Wadi in Israel, Silicon
              Docks in Dublin, Silicon Hills in Austin, Tech City in London;
              Digital Media City in Seoul, Zhongguancun in Beijing, Cyberjaya in
              Malaysia and Cyberabad in Hyderabad, India.
            </div>
          </div>
        </div>

        <div className="flex h-fit rounded m-2 md:m-5">
          <div className="flex-col h-full w-full bg-gray-200 space-y-2 p-3 md:p-5">
            <div className=" border-b p-2 w-full border-gray-600">
              <div className="flex text-lg justify-center font-bold text-gray-500 border-b-2 w-full md:text-xl">
                FAQS
              </div>
            </div>
            <div className="flex flex-row justify-center">
              <div className="flex items-center">
                <ControlPointIcon sx={{ color: "grey" }} />
              </div>
              <div className="flex cursor-pointer  w-full text-justify px-5 text-md font-regular text-gray-500">
                Know our Product?
              </div>
            </div>

            <div className="flex flex-row justify-center">
              <div className="flex items-center">
                <ControlPointIcon sx={{ color: "grey" }} />
              </div>
              <div className="flex cursor-pointer  w-full text-justify px-5 text-md font-regular text-gray-500">
                Our reputation in the market.
              </div>
            </div>

            <div className="flex flex-row justify-center">
              <div className="flex items-center">
                <ControlPointIcon sx={{ color: "grey" }} />
              </div>
              <div className="flex cursor-pointer  w-full text-justify px-5 text-md font-regular text-gray-500">
                Annual Turnour?
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTriangle />
    </div>
  );
};

export default About;
