import React from "react";
import LoginTriangle from "../Triangle/Components/Authentication/LoginTriangle";
import FooterTriangle from "../Triangle/Components/Footer/FooterTriangle";
import NavbarAuth from "../Triangle/Components/NavBar/NavbarAuth";
import ModernCard from "../../src/Triangle/Components/Card/ModernCard";
const Login = () => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <NavbarAuth />
      <div>
        <LoginTriangle />
      </div>
      <FooterTriangle />
    </div>
  );
};

export default Login;
