import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import TextFieldsRoundedIcon from "@mui/icons-material/TextFieldsRounded";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import Crop75RoundedIcon from "@mui/icons-material/Crop75Rounded";
import LineWeightRoundedIcon from "@mui/icons-material/LineWeightRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CropRoundedIcon from "@mui/icons-material/CropRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddToPhotosRoundedIcon from "@mui/icons-material/AddToPhotosRounded";

const WhiteBoard = () => {
  const { editor, onReady } = useFabricJSEditor();

  const history = [];
  const [color, setColor] = useState("#35363a");
  const [cropImage, setCropImage] = useState(true);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }

    if (cropImage) {
      editor.canvas.__eventListeners = {};
      return;
    }

    if (!editor.canvas.__eventListeners["mouse:wheel"]) {
      editor.canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = editor.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }

    if (!editor.canvas.__eventListeners["mouse:down"]) {
      editor.canvas.on("mouse:down", function (opt) {
        var evt = opt.e;
        if (evt.ctrlKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:move"]) {
      editor.canvas.on("mouse:move", function (opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:up"]) {
      editor.canvas.on("mouse:up", function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }

    editor.canvas.renderAll();
  }, [editor]);

  const addBackground = () => {
    if (!editor || !fabric) {
      return;
    }

    fabric.Image.fromURL(
      "https://thegraphicsfairy.com/wp-content/uploads/2019/02/Anatomical-Heart-Illustration-Black-GraphicsFairy.jpg",
      (image) => {
        editor.canvas.setBackgroundImage(
          image,
          editor.canvas.renderAll.bind(editor.canvas)
        );
      }
    );
  };

  const fromSvg = () => {
    fabric.loadSVGFromString(
      `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
    <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="500" height="500" viewBox="0 0 500 500" xml:space="preserve">
    <desc>Created with Fabric.js 5.3.0</desc>
    <defs>
    </defs>
    <g transform="matrix(1 0 0 1 662.5 750)"  >
      <image style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  xlink:href="https://thegraphicsfairy.com/wp-content/uploads/2019/02/Anatomical-Heart-Illustration-Black-GraphicsFairy.jpg" x="-662.5" y="-750" width="1325" height="1500"></image>
    </g>
    <g transform="matrix(1 0 0 1 120.5 120.5)"  >
    <circle style="stroke: rgb(53,54,58); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-opacity: 0; fill-rule: nonzero; opacity: 1;"  cx="0" cy="0" r="20" />
    </g>
    <g transform="matrix(1 0 0 1 245.5 200.5)"  >
    <line style="stroke: rgb(53,54,58); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"  x1="-75" y1="-50" x2="75" y2="50" />
    </g>
    <g transform="matrix(1 0 0 1 141.4 220.03)" style=""  >
        <text xml:space="preserve" font-family="Arial" font-size="16" font-style="normal" font-weight="normal" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(53,54,58); fill-rule: nonzero; opacity: 1; white-space: pre;" ><tspan x="-16.9" y="-5.46" >inset</tspan><tspan x="-16.9" y="15.51" >text</tspan></text>
    </g>
    <g transform="matrix(1 0 0 1 268.5 98.5)"  >
    <rect style="stroke: rgb(53,54,58); stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-opacity: 0; fill-rule: nonzero; opacity: 1;"  x="-20" y="-20" rx="0" ry="0" width="40" height="40" />
    </g>
    </svg>`,
      (objects, options) => {
        editor.canvas._objects.splice(0, editor.canvas._objects.length);
        editor.canvas.backgroundImage = objects[0];
        const newObj = objects.filter((_, index) => index !== 0);
        newObj.forEach((object) => {
          editor.canvas.add(object);
        });

        editor.canvas.renderAll();
      }
    );
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.setHeight(600);
    editor.canvas.setWidth(600);
    addBackground();
    editor.canvas.renderAll();
  }, [editor?.canvas.backgroundImage]);

  const toggleSize = () => {
    editor.canvas.freeDrawingBrush.width === 6
      ? (editor.canvas.freeDrawingBrush.width = 5)
      : (editor.canvas.freeDrawingBrush.width = 1);
  };

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    editor.canvas.freeDrawingBrush.color = color;
    editor.setStrokeColor(color);
  }, [color]);

  const toggleDraw = () => {
    editor.canvas.isDrawingMode = !editor.canvas.isDrawingMode;
  };
  const undo = () => {
    if (editor.canvas._objects.length > 0) {
      history.push(editor.canvas._objects.pop());
    }
    editor.canvas.renderAll();
  };
  const redo = () => {
    if (history.length > 0) {
      editor.canvas.add(history.pop());
    }
  };

  const clear = () => {
    editor.canvas._objects.splice(0, editor.canvas._objects.length);
    history.splice(0, history.length);
    editor.canvas.renderAll();
  };

  const removeSelectedObject = () => {
    editor.canvas.remove(editor.canvas.getActiveObject());
  };

  const onAddCircle = () => {
    editor.addCircle();
    // editor.addLine();
  };
  const onAddRectangle = () => {
    editor.addRectangle();
  };
  const addText = () => {
    editor.addText("insert text");
  };

  const exportSVG = () => {
    const svg = editor.canvas.toSVG();
    console.info(svg);
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row bg-gray-200 w-fit p-1 border-2  border-t-0  rounded-bl-xl rounded-br-xl space-x-2 ">
        <button
          className="flex flex-col text-xs items-center p-2 font-light hover:bg-gray-300 rounded-lg "
          onClick={onAddCircle}
        >
          <Brightness1OutlinedIcon fontSize="medium" />
          circle
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={onAddRectangle}
          disabled={!cropImage}
        >
          <Crop75RoundedIcon fontSize="medium" />
          Rectangle
        </button>
        <button
          className="flex font-light flex-col text-xs align-middle justify-items-center  items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={addText}
          disabled={!cropImage}
        >
          <TextFieldsRoundedIcon fontSize="medium" />
          Text
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={toggleDraw}
          disabled={!cropImage}
        >
          <DrawRoundedIcon fontSize="medium" />
          Draw
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={clear}
          disabled={!cropImage}
        >
          <ClearRoundedIcon fontSize="medium" />
          Clear
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={undo}
          disabled={!cropImage}
        >
          <UndoRoundedIcon fontSize="medium" />
          Undo
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={redo}
          disabled={!cropImage}
        >
          <RedoRoundedIcon fontSize="medium" />
          Redo
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={toggleSize}
          disabled={!cropImage}
        >
          <LineWeightRoundedIcon fontSize="medium" />
          ToggleSize
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={removeSelectedObject}
          disabled={!cropImage}
        >
          <DeleteRoundedIcon fontSize="medium" />
          Delete
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={(e) => setCropImage(!cropImage)}
        >
          <CropRoundedIcon fontSize="medium" />
          Crop
        </button>
        <label
          disabled={!cropImage}
          className="flex font-light flex-col text-xs items-center  hover:bg-gray-300 rounded-lg "
        >
          <input
            disabled={!cropImage}
            type="color"
            value={color}
            className="p-1"
            onChange={(e) => setColor(e.target.value)}
          />
          Color
        </label>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={exportSVG}
          disabled={!cropImage}
        >
          {" "}
          <SaveRoundedIcon fontSize="medium" />
          Save
        </button>
        <button
          className="flex font-light flex-col text-xs items-center p-2 hover:bg-gray-300 rounded-lg "
          onClick={fromSvg}
          disabled={!cropImage}
        >
          <AddToPhotosRoundedIcon fontSize="medium" />
          add
        </button>
      </div>
      <div>
        <div
          style={
            {
              // border: `3px ${!cropImage ? "dotted" : "solid"} Green`,
            }
          }
          className="w-full bg-gray-900 h-full"
        >
          <FabricJSCanvas className=" w-full" onReady={onReady} />
        </div>
      </div>
    </div>
  );
};
export default WhiteBoard;
