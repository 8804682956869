import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BlogDataService from "../../Service/DataService";
import axiosRequest from "../../Service/http-common";

const BlogEdit = (props) => {
  const { id } = useParams();
  let navigate = useNavigate();

  const initialBlogState = {
    id: null,
    heading: "",
    subHeading: "",
    introDescription: "",
    mainDescription: "",
    conclusionDescription: "",
    author: "",
    published: false,
  };
  const [currentBlog, setCurrentBlog] = useState(initialBlogState);
  const [message, setMessage] = useState("");

  const getBlog = (id) => {
    axiosRequest
      .get(`/blog/${id}`)
      .then((response) => {
        setCurrentBlog(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) getBlog(id);
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentBlog({ ...currentBlog, [name]: value });
  };

  const updatePublished = (status) => {
    var data = {
      id: currentBlog.id,
      heading: currentBlog.heading,
      subHeading: currentBlog.subHeading,
      introDescription: currentBlog.introDescription,
      mainDescription: currentBlog.mainDescription,
      conclusionDescription: currentBlog.conclusionDescription,
      author: currentBlog.author,
      published: status,
    };

    axiosRequest
      .put(`/blog/${currentBlog.id}`, data)
      .then((response) => {
        setCurrentBlog({ ...currentBlog, published: status });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateBlog = () => {
    axiosRequest
      .put(`/blog/${currentBlog.id}`, currentBlog)
      .then((response) => {
        console.log(response.data);
        setMessage("The Blog was updated successfully!");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteBlog = () => {
    axiosRequest
      .delete(`/blog/${currentBlog.id}`)
      .then((response) => {
        console.log(response.data);
        navigate("/Stories");
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div>
      {currentBlog ? (
        <div className="edit-form">
          <h4>Storiy Edit</h4>
          <form>
            <div className="form-group">
              <label htmlFor="heading">heading</label>
              <input
                type="text"
                className="form-control"
                id="heading"
                name="heading"
                value={currentBlog.heading}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="heading">Sub Heading</label>
              <input
                type="text"
                className="form-control"
                id="subHeading"
                name="subHeading"
                value={currentBlog.subHeading}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="introDescription">Introduction</label>
              <input
                type="text"
                className="form-control"
                id="introDescription"
                name="introDescription"
                value={currentBlog.introDescription}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="mainDescription">Main content Description</label>
              <input
                type="text"
                className="form-control"
                id="mainDescription"
                name="mainDescription"
                value={currentBlog.mainDescription}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="conclusionDescription">Conclusion</label>
              <input
                type="text"
                className="form-control"
                id="conclusionDescription"
                name="conclusionDescription"
                value={currentBlog.conclusionDescription}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Author</label>
              <input
                type="text"
                className="form-control"
                id="author"
                name="author"
                value={currentBlog.author}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label>
                <strong>Status:</strong>
              </label>
              {currentBlog.published ? "Published" : "Pending"}
            </div>
          </form>

          {currentBlog.published ? (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updatePublished(false)}
            >
              UnPublish
            </button>
          ) : (
            <button
              className="badge badge-primary mr-2"
              onClick={() => updatePublished(true)}
            >
              Publish
            </button>
          )}

          <button className="badge badge-danger mr-2" onClick={deleteBlog}>
            Delete
          </button>

          <button
            type="submit"
            className="badge badge-success"
            onClick={updateBlog}
          >
            Update
          </button>
          <p>{message}</p>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a Blog...</p>
        </div>
      )}
    </div>
  );
};

export default BlogEdit;
